<script>

    const DEBUG = false;
    import settings from '../../settings';
    import {LatLng, Browser} from 'leaflet';
    import {LMap} from 'vue2-leaflet';
    import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
    import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

    import CoverageMapEnsemble from './components/coverage-map-ensemble.vue';
    import CoverageMapProgram from './components/coverage-map-program.vue';

    const MAP_OPTIONS = {
        type: 'roadmap',
        styles: [
            {
                elementType: 'labels', stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: "all",
                elementType: "all",
                stylers: [
                    {
                        saturation: -90
                    }
                ]
            },
            {
                featureType: 'water',
                stylers: [
                    {
                        saturation: 0,
                        color: '#777777'
                    }
                ]
            }
        ]
    };

    const CLUSTER_OPTIONS = {
        disableClusteringAtZoom: 11,
        showCoverageOnHover: false,
    };

    export default {
        name: 'CoverageMap',
        components: {
            LMap,
            CoverageMapEnsemble,
            CoverageMapProgram,
            'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant,
            'v-marker-cluster': Vue2LeafletMarkerCluster
        },
        props: {
            ct: String,
            uuid: String,
            mode: String,
            latitude: String, // passed from html data attribute, so only string possible
            longitude: String, // passed from html data attribute, so only string possible
            initialZoom: String, // passed from html data attribute, so only string possible
            legend: String, // passed from html data attribute, so only string possible
        },
        data() {
            return {
                overlay_bounds: [
                    [46.4095227560944, 7.19260670799664],
                    [48.2078105772389, 9.82700440311447]
                ],
                default_zoom: 10,
                // default_zoom: parseInt(this.initialZoom),
                bounds: null,
                input_focus: false,
                is_mobile: Browser.mobile,
                is_fullscreen: false,
                api_key: settings.GOOGLE_MAPS_API_KEY,
                map_options: MAP_OPTIONS,
                cluster_options: CLUSTER_OPTIONS,

                // override passed props
                forced_ct: null,
                forced_uuid: null,
            }
        },
        mounted() {

            if (this.ct && this.uuid && this.ct === 'infrastructure.program') {
                // 'single program' - additionally load coverage
                if (this.ensembles.length < 1) {
                    this.$store.dispatch('infrastructure/get_ensembles');
                }
            }

            if (this.ct && this.ct === 'infrastructure.ensemble') {
                // 'single ensemble' - program data not needed
                if (this.ensembles.length < 1) {
                    this.$store.dispatch('infrastructure/get_ensembles');
                }
            }

            if(this.is_embed_mode) {
                this.$store.dispatch('infrastructure/get_programs');
            }

            setTimeout(() => {
                this.reset_size();
            }, 1000)

        },
        computed: {

            is_embed_mode() {
                return (this.mode && this.mode === 'embed');
            },

            zoom() {
              if(this.initialZoom) {
                  return parseInt(this.initialZoom);
              }
              return this.default_zoom;
            },

            center() {
                if (this.latitude && this.longitude) {
                    return [parseFloat(this.latitude), parseFloat(this.longitude)];
                }
                return [46.801111, 8.226667];
            },
            _ensembles() {
                return this.$store.getters['infrastructure/ensembles'];
            },
            _programs() {
                return this.$store.getters['infrastructure/programs'];
            },
            ensembles() {
                if(this.selected_program && this.selected_program.ensembles) {
                    let selected_ensemble_uuids = this.selected_program.ensembles.map(({uuid}) => uuid);
                    return this._ensembles.filter((obj) => selected_ensemble_uuids.indexOf(obj.uuid) > -1);
                }
                return this._ensembles
            },
            programs() {
                if(this.selected_program_uuid && this.selected_program) {
                    return [this.selected_program]
                }
                return this._programs
            },

            selected_ensemble_uuid() {

                if(this.forced_ct && this.forced_uuid && this.forced_ct === 'infrastructure.ensemble') {
                    console.debug('this.forced_uuid', this.forced_uuid)
                    return this.forced_uuid;
                }

                if(this.ct === 'infrastructure.ensemble') {
                    return this.uuid;
                }

                if (this.ct && this.uuid && this.ct === 'infrastructure.program') {
                    if(this.selected_program) {
                        return this.selected_program.primary_ensemble_uuid
                    }
                }
                return null
            },
            selected_ensemble() {
                const current_index = this.ensembles.map(({uuid}) => uuid).indexOf(this.selected_ensemble_uuid);
                if(current_index < 0) {
                    return null;
                }
                return this._ensembles[current_index];
            },
            selected_program_uuid() {

                if(this.forced_ct && this.forced_uuid && this.forced_ct === 'infrastructure.program') {
                    return this.forced_uuid;
                }

                if(this.ct === 'infrastructure.program') {
                    return this.uuid;
                }
                return null
            },
            selected_program() {
                const current_index = this._programs.map(({uuid}) => uuid).indexOf(this.selected_program_uuid);
                if(current_index < 0) {
                    return null;
                }
                return this._programs[current_index];
            },
            // visibility
            ensembles_visible() {
                if (! this.uuid && this.ct && this.ct === 'infrastructure.program') {
                    return false;
                }
                return true;
            },
            programs_visible() {

                // don't show program icon in embed mode
                if(this.is_embed_mode) {
                    return false;
                }

                // don't show programs in 'ensemble' mode (detail & list)
                if (this.ct && this.ct === 'infrastructure.ensemble') {
                    return false;
                }
                return true;
            },
        },

        methods: {

            set_fullscreen: function () {
                this.is_fullscreen = true;
                const html = document.getElementsByTagName('html')[0];
                html.classList.add('fullscreen-coverage-map');
                html.scrollTop = 0;
                this.reset_size();
            },

            set_inline: function () {
                this.is_fullscreen = false;
                const html = document.getElementsByTagName('html')[0];
                html.classList.remove('fullscreen-coverage-map');
                this.reset_size();
            },

            toggle_fullscreen: function () {
                if (this.is_fullscreen) {
                    this.set_inline();
                } else {
                    this.set_fullscreen();
                }
            },

            reset_size: function () {
                this.$nextTick(() => {
                    this.$refs.coverage_map.mapObject.invalidateSize();
                })
            },

            set_view: function (center, zoom) {
                this.$refs.coverage_map.mapObject.setView(new LatLng(center[0], center[1]), zoom, {animation: true});
            },

            navigate_to: function (obj) {
                if (DEBUG) console.debug('navigate_to:', obj);

                // in full-screen mode we navigate ensembles inline in the map
                if(this.is_fullscreen && obj.ct === 'infrastructure.ensemble' && this.ct != 'infrastructure.program') {
                    this.forced_ct = obj.ct;
                    this.forced_uuid = obj.uuid;
                    return;
                }

                // trigger 'conventional' navigations...
                this.set_inline();
                const _e = new CustomEvent('navigate:to', {
                  detail: {
                    url: obj.detail_url
                  }
                });
                document.dispatchEvent(_e);
            },
        }
    }
</script>

<style lang="scss">
    .fullscreen-coverage-map {
        overflow: hidden;

        .page-bg-container {
            display: none;
        }

        .topbar {
            display: none;
        }

        .content {
            .top-section {
                margin: 0;
            }
        }
    }
</style>

<style lang="scss" scoped>
    .coverage-map {
        //background-color: $primary-color;
        z-index: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;

        &.is-fullscreen {
            position: fixed;
            height: 100vh;
            width: 100vw;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 5;
        }

    }

    .fullscreen-toggle {
        z-index: 1000;
        position: absolute;
        top: 6px;
        right: 6px;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 5px 20px 1px;
        cursor: pointer;

        font-size: 84%;

        &:hover {
            background-color: #fafafa;
        }
    }

    .legend {
        z-index: 1000;
        padding: 8px 8px 4px;
        line-height: 1;
        position: absolute;
        bottom: 30px;
        left: 6px;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.2);

        min-width: 210px;
        font-size: 84%;

        &__title {
            margin-bottom: 12px;
        }
        &__item-container {
          margin-bottom: 5px;
        }
        &__item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }

            &__color {
                display: inline-block;
                width: 20px;
                height: 20px;

                &--indoor {
                    background-color: #fff000;
                }

                &--outdoor {
                    background-color: #85ebd4;
                }
            }
            &__label {
                display: inline-block;
                padding: 4px 0 0 8px;
            }
        }

        &__appendix {
            font-size: 80%;
            opacity: .65;
            padding: 4px 0 1px;
        }
    }
</style>

<template>
    <div
        class="coverage-map"
        v-bind:class="{'is-fullscreen': is_fullscreen }">

        <l-map
            style="height: 100%; width: 100%"
            ref="coverage_map"
            :zoom="zoom"
            :maxZoom="20"
            :minZoom="6"
            :options="{dragging: !is_mobile, touchZoom: is_mobile}"
            :center="center">

            <v-tilelayer-googlemutant
                :apikey="api_key"
                :options="map_options"></v-tilelayer-googlemutant>

            <v-marker-cluster
                v-if="(ensembles_visible || programs_visible)"
                :options="cluster_options">
                <div
                    v-if="ensembles_visible">
                    <coverage-map-ensemble
                        v-for="ensemble in ensembles"
                        @navigate_to="navigate_to"
                        :selected_ensemble_uuid="selected_ensemble_uuid"
                        :ensemble="ensemble"
                        :show_overlay="(selected_program != null || selected_ensemble_uuid === ensemble.uuid)"
                        :expanded="(selected_program != null || selected_ensemble_uuid === ensemble.uuid)"
                        :key="ensemble.uuid"></coverage-map-ensemble>
                </div>
                <div
                    v-if="programs_visible">
                    <coverage-map-program
                        v-for="program in programs"
                        :selected_program_uuid="selected_program_uuid"
                        :program="program"
                        :key="program.uuid"></coverage-map-program>
                </div>
            </v-marker-cluster>

            <!--
            <v-marker-cluster
                v-if="programs_visible"
                :options="cluster_options">
                <coverage-map-program
                    v-for="program in programs"
                    :selected_program_uuid="selected_program_uuid"
                    :program="program"
                    :key="program.uuid"></coverage-map-program>
            </v-marker-cluster>
            -->
        </l-map>
        <div v-if="(! is_embed_mode)" class="fullscreen-toggle" @click="toggle_fullscreen">
            <span>{{ $t('fullscreen') }}</span>
        </div>
        <div class="legend">
            <div v-if="(is_embed_mode && selected_program)" class="legend__title">
                <span>{{ selected_program.name }}</span>
            </div>
            <div v-if="(legend === 'outdoor')" class="legend__item-container">
              <div class="legend__item">
                  <span class="legend__item__color legend__item__color--outdoor"></span>
                  <span class="legend__item__label">{{ $t('outdoorReception') }}</span>
              </div>
            </div>
            <div v-else class="legend__item-container">
              <div class="legend__item">
                  <span class="legend__item__color legend__item__color--indoor"></span>
                  <span class="legend__item__label">{{ $t('indoorReception') }}</span>
              </div>
              <div class="legend__item">
                  <span class="legend__item__color legend__item__color--outdoor"></span>
                  <span class="legend__item__label">{{ $t('outdoorReception') }}</span>
              </div>
            </div>
            <div class="legend__appendix">
                {{ $t('disclaimer') }}
            </div>
        </div>
    </div>
</template>

<i18n>
{
  "en": {
    "fullscreen": "Fullscreen",
    "indoorReception": "Indoor Reception",
    "outdoorReception": "Outdoor Reception",
    "disclaimer": "Simulation, without engagement."
  },
  "de": {
    "fullscreen": "Vollbild",
    "indoorReception": "Indoor Empfang",
    "outdoorReception": "Outdoor Empfang",
    "disclaimer": "Simulation, Angaben ohne Gewähr."
  }
}
</i18n>
